<template>
  <v-container
    fluid
    class="crawler"
  >
    <v-row>
      <v-col>
        <h1 class="mb-4">
          Bug Crawler
        </h1>
        <v-btn
          color="accent"
          class="mr-3"
          disabled
          @click="initCustomSession"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        xs="12"
        sm="6"
        md="4"
      >
        <v-card>
          <v-card-title>
            <span class="headline">New Session</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="formURL"
                    label="URL*"
                    placeholer="http://localhost:8080"
                    hint="test url"
                    required
                  />
                  <v-text-field
                    v-model="formClient"
                    label="Client*"
                    hint="bain, tidal, etc."
                    required
                  />
                  <v-text-field
                    v-model="formUsername"
                    label="Username or Email*"
                    hint="test user's username"
                    required
                  />
                  <v-text-field
                    v-model="formPassword"
                    label="Password*"
                    type="password"
                    hint="test user's password"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              id="crawl-submit"
              color="primary"
              :disabled="!submitEnabled"
              large
              @click.exact="handleSubmit"
            >
              Crawl
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { startBugCrawler } from '../services'
export default {
  name: 'crawler',
  data: () => ({
    modalVisible: false,
    formURL: '',
    formClient: '',
    formSuccess: false,
    formUsername: '',
    formPassword: '',
    formError: false,
    formLoading: false
  }),
  computed: {
    submitEnabled () {
      return Boolean(this.formURL.length) && Boolean(this.formUsername.length) && Boolean(this.formPassword.length)
    }
  },
  methods: {
    initCustomSession () {
      this.modalVisible = true
    },
    async handleSubmit () {
      this.modalVisible = false
      this.formLoading = true
      try {
        await startBugCrawler({
          client: this.formClient,
          url: this.formURL,
          username: this.formUsername,
          password: this.formPassword
        })
      } catch (err) {
        this.formError = true
      } finally {
        this.formLoading = false
      }
    }
  }
}
</script>
